import * as Yup from 'yup';

export interface IRequestCallForm {
  name: string;
  personal_account: string;
  phone: string;
}

export const requestCallFormShema: Yup.ObjectSchema<IRequestCallForm> =
  Yup.object({
    name: Yup.string()
      .trim()
      .required('Поле обязательно для заполнения')
      .max(255),
    personal_account: Yup.string()
      .required('Поле обязательно для заполнения')
      .matches(/^[0-9]+$/, 'Поле должно содержать только цифры')
      .max(2147483647, 'Вы превысили максимальное значение'),
    phone: Yup.string()
      .required('Поле обязательно для заполнения')
      .matches(
        /^([+]?[7]+)\s?\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
        'Неверный формат мобильного номера',
      ),
  });
