import React, { createContext, PropsWithChildren, useContext } from 'react';
import useMedia from 'react-use/lib/useMedia';

interface IAuthCtx {
  isDesktop: boolean;
  isLaptop: boolean;
  isTabled: boolean;
  isMobile: boolean;
}

const MediaQueryContext = createContext<IAuthCtx>({
  isDesktop: true,
  isLaptop: false,
  isTabled: false,
  isMobile: false,
});

export const MediaQueryProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const isDesktop = useMedia('(min-width: 1200px)');
  const isLaptop = useMedia('(min-width: 769px) and (max-width: 1200px)');
  const isTabled = useMedia('(min-width: 577px) and (max-width: 768px)');
  const isMobile = useMedia('(max-width: 576px)');

  return (
    <MediaQueryContext.Provider
      value={{ isDesktop, isLaptop, isTabled, isMobile }}
    >
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useCssQuery = () => useContext(MediaQueryContext);
