import { clsx } from 'clsx';
import React from 'react';

import styles from './Label.module.css';

interface ILabelProps {
  mainText: string;
  optionalText?: string;
  size?: 's' | 'm';
}

export const Label: React.FC<ILabelProps> = (props) => {
  const { mainText, optionalText, size = 'm' } = props;

  return (
    <div className={styles.root}>
      <span
        className={clsx(styles.mainText, {
          [styles.small]: size === 's',
        })}
      >
        {mainText}
      </span>
      {optionalText && (
        <span className={styles.optionalText}>{optionalText}</span>
      )}
    </div>
  );
};
