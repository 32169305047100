import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { NotFoundPage } from '@/components/NotFoundPage';

import RequestsArchiveRoute from './Archive/ArchiveFreeRoute';
import RequestsFreeRoute from './Free/RequestsFreeRoute';
import { useInitizlizedRequests } from './Free/useInitizlizedRequests';
import RequestsInWorkRoute from './InWork/RequestsInWorkRoute';

const RequestsAdminRoute: React.FC = () => {
  useInitizlizedRequests();

  return (
    <Routes>
      <Route path="free/*" element={<RequestsFreeRoute />} />
      <Route path="in-work/*" element={<RequestsInWorkRoute />} />
      <Route path="archive/*" element={<RequestsArchiveRoute />} />

      <Route path="/" element={<Navigate to="/free" />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default RequestsAdminRoute;
