import { clsx } from 'clsx';
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { isIOS } from 'react-device-detect';
import { UseFormRegisterReturn } from 'react-hook-form';

import styles from './Input.module.css';

interface IInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  placeholder?: string;
  error?: boolean;
  type?: string;
  appearance?: 'auth' | 'default';
  disabled?: boolean;
  className?: string;
  register?: UseFormRegisterReturn;
}

export const Input: React.FC<IInputProps> = (props) => {
  const {
    placeholder,
    type = 'text',
    appearance = 'default',
    disabled,
    error,
    className,
    register,
    ...restProps
  } = props;

  return (
    <input
      {...restProps}
      {...register}
      className={clsx(styles.root, className, {
        [styles.error]: error,
        [styles.auth]: appearance === 'auth',
        [styles.isIOS]: isIOS,
      })}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};
