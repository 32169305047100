import React from 'react';

import styles from './ErrorFallback.module.css';

export const ErrorFallback: React.FC = () => {
  return (
    <div className={styles.root}>
      <p className={styles.errorText}>
        Что-то пошло не так. Работаем над исправлением ошибок
      </p>
    </div>
  );
};
