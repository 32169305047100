import { clsx } from 'clsx';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useSelector } from 'react-redux';

import { IconProfile } from '@/components/Icons/IconProfile';
import Loading from '@/components/Loading';
import { EUserRole } from '@/consts';
import { useSwitchPersonalAccontInProcess } from '@/providers/SwithingPersonalAccount';
import { RootState } from '@/redux';

import { ProfilePopover } from './Popover';
import styles from './Profile.module.css';

export const Profile: React.FC = () => {
  const { user } = useSelector((state: RootState) => state);
  const { switchInProcess } = useSwitchPersonalAccontInProcess();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'profilePopover',
  });

  return (
    <>
      <div
        {...bindTrigger(popupState)}
        className={clsx(styles.root, {
          [styles.popoverOpened]: popupState.isOpen,
        })}
      >
        <div className={styles.icon}>
          <IconProfile />
        </div>

        {switchInProcess ? (
          <div className={styles.loadingWrapper}>
            <Loading variant="internal" />
          </div>
        ) : (
          <div className={styles.data}>
            <div className={styles.userData}>
              {user.data?.user_role === EUserRole.Customer && (
                <span>
                  Лицевой счет
                  <span className={styles.userDataId}>
                    {user.data?.current_account?.account_number ??
                      'отсутствует'}
                  </span>
                </span>
              )}

              {user.data?.user_role === EUserRole.Operator && 'Оператор'}
              {user.data?.user_role === EUserRole.Admin && 'Администратор'}
            </div>

            <div className={styles.userAddress}>
              {user.data?.current_account?.address}
            </div>
          </div>
        )}
      </div>

      <ProfilePopover popupState={popupState} />
    </>
  );
};
