import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { updateServerError } from './slices/error';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (action.payload?.status === 500) {
      api.dispatch(updateServerError({ isServerError: true }));
    } else {
      return next(action);
    }
  };
