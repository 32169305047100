import { createApi } from '@reduxjs/toolkit/query/react';

import { IPaymentUrlRequest, IPreparePaymentData } from '@/interfaces/payment';

import { baseQuery } from './baseQuery';

export const paymentWithoutRegisterApi = createApi({
  reducerPath: 'paymentWithoutRegisterApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getPreparePaymentDataWithoutRegister: builder.query<
      IPreparePaymentData,
      { personalAccountId: string }
    >({
      query: (data) => ({
        url: `pay/get-data?personal_account=${data.personalAccountId}`,
        method: 'GET',
      }),
    }),
    getPaymentUrlWithoutRegister: builder.mutation<
      { url: string },
      IPaymentUrlRequest
    >({
      query: (data) => ({
        url: `pay/get-url`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetPreparePaymentDataWithoutRegisterQuery,
  useGetPaymentUrlWithoutRegisterMutation,
} = paymentWithoutRegisterApi;
