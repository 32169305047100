import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IEmailVerifyRequest,
  IForgotPasswordByPhoneRequest,
  IForgotPasswordByPhoneResponse,
  IForgotPasswordRequest,
  IForgotPasswordResponse,
  ILoginRequest,
  IRecoverPasswordByPhoneRequest,
  IRecoverPasswordByPhoneResponse,
  IRecoverPasswordRequest,
  IRecoverPasswordResponse,
  IRegisterRequest,
  IRegisterResponse,
} from '@/interfaces/auth';
import { ILoginResponse } from '@/interfaces/user';

import { baseQuery } from './baseQuery';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    register: builder.mutation<IRegisterResponse, IRegisterRequest>({
      query: (data) => ({
        url: 'auth/register',
        method: 'POST',
        body: data,
      }),
    }),
    emailVerify: builder.query<unknown, IEmailVerifyRequest>({
      query: (data) => ({
        url: `email/verify/${data.verifyCode}`,
        method: 'GET',
      }),
    }),
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (data) => ({
        url: `auth/login`,
        method: 'POST',
        body: data,
      }),

      transformResponse(res: ILoginResponse) {
        const accessToken = res.access_token;

        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
        }

        return res;
      },
    }),
    forgotPassword: builder.mutation<
      IForgotPasswordResponse,
      IForgotPasswordRequest
    >({
      query: (data) => ({
        url: 'auth/forget-password',
        method: 'POST',
        body: data,
      }),
    }),
    recoverPassword: builder.mutation<
      IRecoverPasswordResponse,
      IRecoverPasswordRequest
    >({
      query: (data) => ({
        url: 'auth/reset-password',
        method: 'POST',
        body: data,
      }),
    }),
    forgotPasswordByPhone: builder.mutation<
      IForgotPasswordByPhoneResponse,
      IForgotPasswordByPhoneRequest
    >({
      query: (data) => ({
        url: 'sms/send-reset-code',
        method: 'POST',
        body: data,
      }),
    }),
    recoverPasswordByPhone: builder.mutation<
      IRecoverPasswordByPhoneResponse,
      IRecoverPasswordByPhoneRequest
    >({
      query: (data) => ({
        url: 'reset-password-by-code',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useLazyEmailVerifyQuery,
  useForgotPasswordMutation,
  useRecoverPasswordMutation,
  useForgotPasswordByPhoneMutation,
  useRecoverPasswordByPhoneMutation,
} = authApi;
