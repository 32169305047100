import { clsx } from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import useToggle from 'react-use/lib/useToggle';

import { IconArrow } from '@/components/Icons/IconArrow';
import { RootState } from '@/redux';

import { useAvailablePersonalAccount } from '../useAvailablePersonalAccount';
import { AccountRow } from './AccountRow';
import styles from './ProfileMobile.module.css';

export const ProfileMobile: React.FC = () => {
  const [isOpen, toggleOpen] = useToggle(false);
  const { user } = useSelector((state: RootState) => state);
  const personalAccountForSwitch = useAvailablePersonalAccount();

  return (
    <div className={styles.root}>
      <div
        className={styles.selectedAccount}
        onClick={() => {
          if (
            personalAccountForSwitch &&
            personalAccountForSwitch?.length > 0
          ) {
            toggleOpen();
          }
        }}
      >
        <AccountRow
          title="Лицевой счет"
          data={user.data?.current_account.account_number ?? 'отсутствует'}
          address={user.data?.current_account.address}
          isSelected
        />

        {personalAccountForSwitch && personalAccountForSwitch.length > 0 && (
          <IconArrow
            className={clsx(styles.spoilerIcon, {
              [styles.isOpen]: isOpen,
            })}
          />
        )}
      </div>

      {isOpen && (
        <div className={styles.content}>
          {personalAccountForSwitch?.map((personalAccount) => {
            return (
              <AccountRow
                key={personalAccount.id}
                title="Лицевой счет"
                personalAccountId={personalAccount.id}
                data={personalAccount.account_number}
                address={personalAccount.address}
                toggleOpen={toggleOpen}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
