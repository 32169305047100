import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IBlockUserRequest,
  IChangePasswordRequest,
  IChangePasswordResponse,
  IDeleteUserRequest,
  ILoginResponse,
  ISendSmsRequest,
  ISendSmsResponse,
  ISmsVerifyRequest,
  ISmsVerifyResponse,
  IUserData,
} from '@/interfaces/user';

import { baseQueryWithReauth } from './baseQuery';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getUserProfile: builder.query<IUserData, void>({
      query: () => ({
        url: 'auth/user-profile',
        method: 'GET',
      }),
    }),
    changePassword: builder.mutation<
      IChangePasswordResponse,
      IChangePasswordRequest
    >({
      query: (data) => ({
        url: 'auth/change-password',
        method: 'POST',
        body: data,
      }),
    }),
    sendSms: builder.mutation<ISendSmsResponse, ISendSmsRequest>({
      query: (data) => ({
        url: 'sms/send',
        method: 'POST',
        body: data,
      }),
    }),
    smsVerify: builder.mutation<ISmsVerifyResponse, ISmsVerifyRequest>({
      query: (data) => ({
        url: 'sms/verify',
        method: 'POST',
        body: data,
      }),
    }),
    blockUser: builder.mutation<IBlockUserRequest, void>({
      query: () => ({
        url: 'user/block',
        method: 'POST',
      }),
    }),
    deleteUser: builder.mutation<IDeleteUserRequest, void>({
      query: () => ({
        url: 'user/delete',
        method: 'DELETE',
      }),
    }),
    loginIntoCustomer: builder.mutation<ILoginResponse, { customerId: number }>(
      {
        query: (data) => ({
          url: `user/impersonate/${data.customerId}`,
          method: 'POST',
        }),
      },
    ),
    returnIntoOperator: builder.mutation<ILoginResponse, void>({
      query: () => ({
        url: 'user/leaveImpersonation',
        method: 'POST',
      }),

      transformResponse(res: ILoginResponse) {
        const accessToken = res.access_token;

        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
        }

        return res;
      },
    }),
  }),
});

export const {
  useLazyGetUserProfileQuery,
  useChangePasswordMutation,
  useSendSmsMutation,
  useSmsVerifyMutation,
  useBlockUserMutation,
  useDeleteUserMutation,
  useLoginIntoCustomerMutation,
  useReturnIntoOperatorMutation,
} = userApi;
