import { PopupState } from 'material-ui-popup-state/hooks';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSnackbarError } from '@/hooks/useSnackbackError';
import { useSnackbarSuccess } from '@/hooks/useSnackbatSuccess';
import { useSwitchPersonalAccontInProcess } from '@/providers/SwithingPersonalAccount';
import { useChangePersonalAccountMutation } from '@/redux/api/personalAccountsApi';
import { useLazyGetUserProfileQuery } from '@/redux/api/userApi';
import { updateUserInfo } from '@/redux/slices/user';

interface ISwitchPersonalAccountParam {
  popupState?: PopupState;
  toggleOpen?: () => void;
}

export function useSwitchPersonalAccount(
  params: ISwitchPersonalAccountParam | undefined,
): {
  onSwitchPersonalAccount: (id: number) => void;
} {
  const { setSwitchInProcess } = useSwitchPersonalAccontInProcess();
  const [getUserProfile] = useLazyGetUserProfileQuery();
  const [changePersonalAccount] = useChangePersonalAccountMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onSuccess = useSnackbarSuccess();
  const displayError = useSnackbarError();

  const onSwitchPersonalAccount = (id: number) => {
    setSwitchInProcess(true);

    changePersonalAccount({ pa_id: id })
      .unwrap()
      .then((response) => {
        getUserProfile()
          .unwrap()
          .then((res) => {
            dispatch(
              updateUserInfo({
                isAuthorized: true,
                data: { ...res },
              }),
            );

            setSwitchInProcess(false);
            onSuccess(response.message);
            params?.popupState?.close();
            params?.toggleOpen?.();
            navigate(`/${pathname.split('/')[1]}`);
          })
          .catch((error) => {
            setSwitchInProcess(false);
            displayError(error.data.message);
          });
      })
      .catch((error) => {
        setSwitchInProcess(false);
        displayError(error.data.message);
      });
  };

  return { onSwitchPersonalAccount };
}
