import React from 'react';
import { useSelector } from 'react-redux';

import { NotificationGlobal } from '@/components/NotificationGlobal';
import { ENotificationType } from '@/consts';
import { RootState } from '@/redux';

import styles from './NotificationGlobalList.module.css';

export const NotificationGlobalList: React.FC = () => {
  const {
    notifications: { globalList },
  } = useSelector((state: RootState) => state);

  return (
    <div className={styles.root}>
      {globalList
        .filter(
          (notification) =>
            notification.type === ENotificationType.User ||
            notification.type === ENotificationType.Global,
        )
        .map((notification) => {
          return (
            <NotificationGlobal
              key={notification.id}
              notification={notification}
            />
          );
        })}
    </div>
  );
};
