import { createApi } from '@reduxjs/toolkit/query/react';

import { IStatistics } from '@/interfaces/statistics';

import { baseQueryWithReauth } from '../baseQuery';

export const statisticsApi = createApi({
  reducerPath: 'statisticsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getStatistics: builder.query<IStatistics, void>({
      query: () => ({
        url: 'getStatistics',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetStatisticsQuery } = statisticsApi;
