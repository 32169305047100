import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from './baseQuery';

export const filesApi = createApi({
  reducerPath: 'filesApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    fileDownload: builder.query<File, { guid: string; name: string }>({
      query: (data) => ({
        url: `files/download/${data.guid}`,
        method: 'GET',
        responseHandler: async (response) => {
          const result = await response.blob();

          const hiddenElement = document.createElement('a');
          const url = window.URL || window.webkitURL;
          const fileUrl = url.createObjectURL(result);

          hiddenElement.href = fileUrl;
          hiddenElement.download = `${data.name}`;
          hiddenElement.click();
          return { data: null };
        },
      }),
    }),
  }),
});

export const { useLazyFileDownloadQuery } = filesApi;
