import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IPaymentHistoryData,
  IPaymentHistoryDetailResponse,
  IPaymentHistoryRequest,
} from '@/interfaces/paymentHistory';

import { baseQueryWithReauth } from './baseQuery';

export const paymentHistoryApi = createApi({
  reducerPath: 'paymentHistoryApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPaymentHistory: builder.query<
      IPaymentHistoryData,
      IPaymentHistoryRequest
    >({
      query: (data) => {
        const monthParam = data.filter?.month
          ? `&filter[month]=${data.filter.month}`
          : '';

        const yearParam = data.filter?.year
          ? `&filter[year]=${data.filter.year}`
          : '';

        const url = `payments/getPaymentHistory?per_page=${10}&page=${data.page}
      ${monthParam}
      ${yearParam}`;

        return {
          url,
          method: 'GET',
        };
      },
    }),
    getPaymentHistoryDetail: builder.query<
      IPaymentHistoryDetailResponse,
      { sourceId: number; date: string; orderId: number | null }
    >({
      query: (data) => ({
        url: `payments/getDetail/${data.sourceId}/${data.date}${
          data.orderId ? `/${data.orderId}` : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetPaymentHistoryQuery, useGetPaymentHistoryDetailQuery } =
  paymentHistoryApi;
