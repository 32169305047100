import { clsx } from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useToggle from 'react-use/lib/useToggle';

import { IconMenu } from '@/components/Icons/IconMenu';
import { IconPhone } from '@/components/Icons/IconPhone';
import { EUserRole } from '@/consts';
import { useCssQuery } from '@/providers/MediaQuery';
import { RootState } from '@/redux';

import styles from './Header.module.css';
import { MobileMenu } from './MobileMenu';
import { NotificationBlock } from './NotificationBlock';
import { Profile } from './Profile';
import { ProfileMobile } from './ProfileMobile';
import { ReturnIntoOperatorButton } from './ReturnButton';

export const Header: React.FC = () => {
  const { user } = useSelector((state: RootState) => state);

  const { isDesktop, isMobile } = useCssQuery();
  const [isShowMobileMenu, toggleShowMobileMenu] = useToggle(false);
  const navigate = useNavigate();

  const onNavigateMain = () => {
    navigate('/');
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(styles.root, {
          [styles.small]: !isDesktop,
          [styles.isMobile]: isMobile,
        })}
      >
        <div className={styles.groupWrapper}>
          <div className={styles.group}>
            <div
              className={styles.iconMenuWrapper}
              onClick={toggleShowMobileMenu}
            >
              {!isDesktop && <IconMenu />}
            </div>

            <img
              src="/svg/logo.svg"
              className={styles.logo}
              onClick={onNavigateMain}
            />

            {isDesktop && user?.data?.user_role === EUserRole.Customer && (
              <div className={styles.phone}>
                <IconPhone className={styles.phoneIcon} />
                <div className={styles.phoneData}>
                  <a
                    className={styles.phoneNumber}
                    href={`tel:${process.env.REACT_APP_COMMON_PHONE_NUMBER}`}
                  >
                    {process.env.REACT_APP_COMMON_PHONE_NUMBER}
                  </a>
                  <div className={styles.phoneLabel}>
                    номер для связи с оператором
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={styles.group}>
            {user.data?.user_role === EUserRole.Customer && (
              <NotificationBlock />
            )}

            {isDesktop && (
              <div className={styles.profile}>
                <Profile />
              </div>
            )}

            {!isMobile && user.data?.impersonate && (
              <ReturnIntoOperatorButton />
            )}
          </div>
        </div>

        {isMobile && user.data?.impersonate && (
          <ReturnIntoOperatorButton fullWidth />
        )}
      </div>

      {!isDesktop && <ProfileMobile />}

      <MobileMenu
        isShowMobileMenu={isShowMobileMenu}
        toggleShowMobileMenu={toggleShowMobileMenu}
      />
    </div>
  );
};
