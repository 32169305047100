import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const RequestsArchivePage = lazy(
  () => import('@/routes/admin/Requests/Archive'),
);
const RequestsArciveDetailsPage = lazy(
  () => import('@/routes/admin/Requests/Archive/Details'),
);

const RequestsArchiveRoute: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<RequestsArchivePage />} />
      <Route
        path="details/:requestId"
        element={<RequestsArciveDetailsPage />}
      />
    </Routes>
  );
};

export default RequestsArchiveRoute;
