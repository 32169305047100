import { createApi } from '@reduxjs/toolkit/query/react';

import { ICustomerList, ICustomerListRequest } from '@/interfaces/customers';

import { baseQueryWithReauth } from '../baseQuery';

export const customersApi = createApi({
  reducerPath: 'customersApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Customers'],
  endpoints: (builder) => ({
    getCustomers: builder.query<ICustomerList, ICustomerListRequest>({
      query: (data) => ({
        url: `user/getAllCustomers?per_page=10&page=${data.page}${
          data ? `&search=${data.search}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: ['Customers'],
    }),
    unBlockCustomer: builder.mutation<{ message: string }, { userId: number }>({
      query: (data) => ({
        url: `user/unblock/${data.userId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Customers'],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useUnBlockCustomerMutation,
} = customersApi;
