import React from 'react';

interface Props {
  className?: string;
}

export const IconNotification: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5258 3.75879C11.2503 3.75879 7.77576 7.23337 7.77576 11.5088V15.2417C7.77576 16.0296 7.43993 17.2309 7.03951 17.9025L5.5541 20.3696C4.63701 21.8938 5.26993 23.5859 6.9491 24.1542C12.5162 26.0142 18.5224 26.0142 24.0895 24.1542C25.6524 23.6375 26.337 21.7905 25.4845 20.3696L23.9991 17.9025C23.6116 17.2309 23.2758 16.0296 23.2758 15.2417V11.5088C23.2758 7.24629 19.7883 3.75879 15.5258 3.75879Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.9154 4.13336C17.515 4.01711 17.1016 3.92669 16.6754 3.87503C15.4354 3.72003 14.2471 3.81044 13.1362 4.13336C13.5108 3.17753 14.4408 2.50586 15.5258 2.50586C16.6108 2.50586 17.5408 3.17753 17.9154 4.13336Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4009 24.6191C19.4009 26.7504 17.6571 28.4941 15.5259 28.4941C14.4667 28.4941 13.485 28.055 12.7875 27.3575C12.09 26.66 11.6509 25.6783 11.6509 24.6191"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
