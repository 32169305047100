import { clsx } from 'clsx';
import { closeSnackbar, CustomContentProps } from 'notistack';
import React, { forwardRef } from 'react';

import { IconSnackbarClose } from '../Icons/IconSnackbarClose';
import { IconSnackbarError } from '../Icons/IconSnackbarError';
import { IconSnackbarSuccess } from '../Icons/IconSnackbarSuccess';
import styles from './Snackbar.module.css';

type ISnackbarProps = CustomContentProps;

export const Snackbar = forwardRef<HTMLDivElement, ISnackbarProps>(
  (props, ref) => {
    const { id, message, variant } = props;

    return (
      <div className={clsx(styles.root, variant)} ref={ref}>
        <div className={styles.info}>
          {variant === 'success' && (
            <IconSnackbarSuccess className={styles.icon} />
          )}

          {variant === 'error' && <IconSnackbarError className={styles.icon} />}

          <div className={styles.message}> {message}</div>
        </div>

        <IconSnackbarClose
          className={styles.closeIcon}
          onClick={() => closeSnackbar(id)}
        />
      </div>
    );
  },
);
