import { clsx } from 'clsx';
import React, { ReactNode } from 'react';

import { FormItemError } from './Error';
import styles from './FormItem.module.css';

interface IFormItemProps {
  errorMessage?: string;
  children?: ReactNode;
  className?: string;
}

export const FormItem: React.FC<IFormItemProps> = (props) => {
  const { errorMessage, className, children } = props;

  return (
    <div className={clsx(styles.root, className)}>
      {children}

      <FormItemError errorMessage={errorMessage} />
    </div>
  );
};
