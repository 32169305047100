import { createApi } from '@reduxjs/toolkit/query/react';

import {
  ICreateNotoficationRequest,
  ICreateNotoficationResponse,
  ISearchPAResponse,
} from '@/interfaces/notification';

import { baseQueryWithReauth } from '../baseQuery';

export const operatorNotificationApi = createApi({
  reducerPath: 'operatorNotificationApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['OperatorNotification'],
  endpoints: (builder) => ({
    createNotification: builder.mutation<
      ICreateNotoficationResponse,
      ICreateNotoficationRequest
    >({
      query: (data) => ({
        url: `notifications/create`,
        method: 'POST',
        body: data,
      }),
    }),
    searchPa: builder.query<Array<ISearchPAResponse>, { search: string }>({
      query: (data) => ({
        url: `pa/getAll?search=${data.search}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useCreateNotificationMutation, useLazySearchPaQuery } =
  operatorNotificationApi;
