import { DatePicker as DatePickerMaterial } from '@mui/x-date-pickers';
import React from 'react';

import styles from './DatePicker.module.css';

interface IDatePickerProps {
  value: Date | null;
  onChange: (...event: any[]) => void;
}

export const DatePicker: React.FC<IDatePickerProps> = (props) => {
  const { value, onChange } = props;

  return (
    <DatePickerMaterial
      value={value}
      className={styles.root}
      onChange={onChange}
    />
  );
};
