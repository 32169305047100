import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const RequestsFreePage = lazy(() => import('@/routes/admin/Requests/Free'));
const RequestsFreeAccountDetailsPage = lazy(
  () => import('@/routes/admin/Requests/Free/Details'),
);

const RequestsFreeRoute: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<RequestsFreePage />} />
      <Route
        path="details/:requestId"
        element={<RequestsFreeAccountDetailsPage />}
      />
    </Routes>
  );
};

export default RequestsFreeRoute;
