import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  IOperatorRequestCount,
  IRequestCategoryInfo,
} from '@/interfaces/requests';

export interface RequestsState {
  freeRequestsCounter: number;
  inWorkRequestsCounter: number;
  categoryList: Array<IRequestCategoryInfo> | null;
  countRequests: IOperatorRequestCount | null;
}

const initialState: RequestsState = {
  freeRequestsCounter: 0,
  inWorkRequestsCounter: 0,
  categoryList: null,
  countRequests: null,
};

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    updateRequestsCounters: (
      state,
      action: PayloadAction<{
        freeRequestsCounter: number;
        inWorkRequestsCounter: number;
      }>,
    ) => {
      state.freeRequestsCounter = action.payload.freeRequestsCounter;
      state.inWorkRequestsCounter = action.payload.inWorkRequestsCounter;
    },
    setRequestCategory: (
      state,
      action: PayloadAction<{
        categoryList: Array<IRequestCategoryInfo>;
      }>,
    ) => {
      state.categoryList = action.payload.categoryList;
    },
    setRequestCount: (
      state,
      action: PayloadAction<{
        countRequests: IOperatorRequestCount;
      }>,
    ) => {
      state.countRequests = action.payload.countRequests;
    },
  },
});

export const { updateRequestsCounters, setRequestCategory, setRequestCount } =
  requestsSlice.actions;

export default requestsSlice.reducer;
