import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { EUserRole } from '@/consts';
import { RootState } from '@/redux';

interface IPrivateRoute {
  roles: Array<EUserRole>;
  children: ReactNode;
}

export const PrivateRoute: React.FC<IPrivateRoute> = (props) => {
  const { roles, children } = props;
  const { user } = useSelector((state: RootState) => state);

  const userHasRequiredRole =
    user.data && roles.includes(user.data.user_role) ? true : false;

  return userHasRequiredRole ? <>{children}</> : <Navigate to={'/'} />;
};
