import { createApi } from '@reduxjs/toolkit/query/react';

import { IEquipmentList } from '@/interfaces/meterReadings';

import { baseQuery } from './baseQuery';

export const checkPersonalAccountApi = createApi({
  reducerPath: 'checkPersonalAccountApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    checkPersonalAccountId: builder.query<
      IEquipmentList,
      { personalAccountId: string }
    >({
      query: (data) => ({
        url: `pa/check/${data.personalAccountId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyCheckPersonalAccountIdQuery } = checkPersonalAccountApi;
