import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ReceiptLinkState {
  inProcess: boolean;
  code?: string;
  guid?: string;
  personalAccountId?: string;
}

const initialState: ReceiptLinkState = {
  inProcess: false,
  code: undefined,
  personalAccountId: undefined,
};

export const receiptLinkSlice = createSlice({
  name: 'receiptLink',
  initialState,
  reducers: {
    updateExternalAddingAccount: (
      state,
      action: PayloadAction<{
        inProcess: boolean;
        code?: string;
        personalAccountId?: string;
      }>,
    ) => {
      state.inProcess = action.payload.inProcess;
      state.code = action.payload.code;
      state.personalAccountId = action.payload.personalAccountId;
    },
  },
});

export const { updateExternalAddingAccount } = receiptLinkSlice.actions;

export default receiptLinkSlice.reducer;
