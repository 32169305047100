import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { IUserData } from '@/interfaces/user';

export interface UserState {
  isAuthorized: boolean;
  data: IUserData | null;
}

const initialState: UserState = {
  isAuthorized: false,
  data: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<UserState>) => {
      state.isAuthorized = action.payload.isAuthorized;
      state.data = action.payload.data;
    },
    clearUserInfo: (state) => {
      state.isAuthorized = false;
      state.data = null;
    },
    logout: (state) => {
      state.isAuthorized = false;
      state.data = null;
    },
  },
});

export const { updateUserInfo, clearUserInfo, logout } = userSlice.actions;

export default userSlice.reducer;
