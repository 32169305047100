export enum EMeterReadingStatus {
  InProgress = 'InProgress',
  Accepted = 'Accepted',
  Declined = 'Declined',
}

export enum ERequestStatus {
  InProgress = 'InProgress',
  Accepted = 'Accepted',
  Declined = 'Declined',
  Created = 'Created',
}

export enum EPaymentHistoryStatus {
  Accepted = 'Accepted',
  Declined = 'Declined',
  InProgress = 'InProgress',
}

export enum ERequestFreeStatus {
  All,
  DeadlineExpires,
  Expired,
}

export enum ENotificationType {
  Default = 'default',
  Request_reply = 'request_reply',
  User = 'user',
  Global = 'global',
}

export enum ESnackbarType {
  Error,
  Success,
}

export enum EPaymentWithoutRegister {
  EnterPersonalAccount,
  Payment,
}

export enum EUserRole {
  Admin = 'admin',
  Operator = 'operator',
  Customer = 'customer',
}

export enum EOperatorStatus {
  Online,
  Offline,
}

export enum EPhoneModalStep {
  SendSms,
  VerifyPhone,
}

export enum EReceiptType {
  Printed,
  Digital,
}

export enum CounterpartiesRequestType {
  Free = 'Свободные',
  InWork = 'В работе',
  Archive = 'Завершенные',
}

export enum EOperatorRequestType {
  InWork = 'В работе',
  Archive = 'Завершенные',
}

export enum ENotificationColor {
  Red = 1,
  Green = 2,
  Yellow = 3,
}

export enum ESwitchUserType {
  Customer,
  Operator,
}

export enum EMetersSendingWithoutRegisterSteps {
  EnterPersonalAccount,
  Sending,
}

export enum EPaymentService {
  Gpb = 'GPB',
  Sber = 'SBER',
  SberQr = 'SBER-QR',
}

export enum EEquipmentType {
  Meter = 'Meter',
  GasConsuming = 'Gas-consuming',
}
