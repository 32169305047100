import { clsx } from 'clsx';
import { PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { INotification } from '@/interfaces/notification';
import { useCssQuery } from '@/providers/MediaQuery';

import styles from './NotificationBlockList.module.css';
import { NotificationMessageItem } from './NotificationMessageItem';

interface INotificationBlockMessageListProps {
  popupState: PopupState;
  notificationList: Array<INotification>;
  countUnreadMessages: number;
}

export const NotificationBlockMessageList: React.FC<
  INotificationBlockMessageListProps
> = (props) => {
  const { popupState, notificationList, countUnreadMessages } = props;
  const { isMobile } = useCssQuery();

  return (
    <div
      className={clsx(styles.root, {
        [styles.isEmpty]: notificationList.length === 0,
        [styles.isMobile]: isMobile,
      })}
    >
      {notificationList.map((notification) => {
        return (
          <NotificationMessageItem
            key={notification.id}
            popupState={popupState}
            data={notification}
          />
        );
      })}

      {countUnreadMessages === 0 && (
        <div className={styles.notNotifications}>
          У вас нет непрочитанных сообщений
        </div>
      )}

      <NavLink
        className={styles.showMore}
        to="/notifications"
        onClick={popupState.close}
      >
        Посмотреть все уведомления
      </NavLink>
    </div>
  );
};
