import { clsx } from 'clsx';
import React from 'react';
import { Controller } from 'react-hook-form';

import { DatePicker } from '@/components/DatePicker';
import { FormItem } from '@/components/FormItem';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { MainButton } from '@/components/MainButton';
import { FormTextArea } from '@/components/TextArea';
import { useCssQuery } from '@/providers/MediaQuery';

import { ColorSelect } from './ColorSelect';
import styles from './Form.module.css';
import { PersonalAccountSelect } from './PersonalAccountSelect';
import useCreateNotification from './useCreateNotification';

export const NotificationCreatingForm: React.FC = () => {
  const { isTabled, isMobile } = useCssQuery();

  const {
    formState: { errors },
    register,
    setValue,
    control,
    handleSubmit,
    isLoading,
    watch,
  } = useCreateNotification();

  return (
    <form
      className={clsx(styles.root, {
        [styles.isTabled]: isTabled,
        [styles.isMobile]: isMobile,
      })}
      onSubmit={handleSubmit}
    >
      <PersonalAccountSelect
        value={watch('for_all')}
        setValue={setValue}
        errorMessage={errors.personalAccounts?.message}
      />

      <ColorSelect setValue={setValue} />

      <FormItem errorMessage={errors.title?.message}>
        <Label mainText="Заголовок уведомления" />
        <Input
          placeholder="Заголовок уведомления"
          register={register('title')}
        />
      </FormItem>

      <FormItem errorMessage={errors.text?.message}>
        <Label mainText="Текст уведомления" />
        <FormTextArea
          placeholder="Текст уведомления..."
          fullWidth
          register={register('text')}
        />
      </FormItem>

      <FormItem errorMessage={errors.due_date?.message}>
        <Label mainText="Дата окончания " />

        <Controller
          control={control}
          name="due_date"
          render={({ field: { value, onChange } }) => (
            <DatePicker value={value ?? null} onChange={onChange} />
          )}
        />
      </FormItem>

      <MainButton fullWidth size="large" isLoading={isLoading}>
        Отправить
      </MainButton>
    </form>
  );
};
