import { clsx } from 'clsx';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { isIOS } from 'react-device-detect';
import { UseFormRegisterReturn } from 'react-hook-form';

import styles from './TextArea.module.css';

interface IFormTextArea
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  register?: UseFormRegisterReturn;
  className?: string;
  fullWidth?: boolean;
}

export const FormTextArea: React.FC<IFormTextArea> = (props) => {
  const { className, fullWidth, register, ...restProps } = props;

  return (
    <textarea
      {...restProps}
      {...register}
      className={clsx(styles.root, className, {
        [styles.fullWidth]: fullWidth,
        [styles.isIOS]: isIOS,
      })}
    />
  );
};
