import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IChangeOperatorRequest,
  IChangeOperatorResponse,
  IOperatorChangeListItem,
  IOperatorRequestCount,
  IPARequestPayload,
  IPARequestsData,
  IReplyRequestData,
  IReplyRequestResponse,
  IRequestArchiveResponse,
  IRequestCounter,
  IRequestFreeResponse,
  IRequestInWorkResponse,
} from '@/interfaces/requests';

import { baseQueryWithReauth } from '../baseQuery';

export const operatorRequestsApi = createApi({
  reducerPath: 'operatorRequestsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['requests'],
  endpoints: (builder) => ({
    getFreeRequests: builder.query<
      IRequestFreeResponse,
      { requestId: number; page: number }
    >({
      query: (data) => ({
        url: `requests/listFree?per_page=10&page=${data.page}&filter[category_id]=${data.requestId}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'requests', id: 'free' }],
    }),
    takeToWork: builder.mutation<IRequestFreeResponse, { requestId: number }>({
      query: (data) => ({
        url: `requests/takeToWork/${data.requestId}`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'requests', id: 'free' }],
    }),
    getRequestCounter: builder.query<IRequestCounter, void>({
      query: () => ({
        url: `requests/getCounters`,
        method: 'GET',
      }),
      providesTags: [
        { type: 'requests', id: 'free' },
        { type: 'requests', id: 'inWork' },
      ],
    }),
    getInWorkRequests: builder.query<
      IRequestInWorkResponse,
      { requestId: number; page: number }
    >({
      query: (data) => ({
        url: `requests/listInWork?per_page=10&page=${data.page}&filter[category_id]=${data.requestId}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'requests', id: 'inWork' }],
    }),
    replyRequest: builder.mutation<IReplyRequestResponse, IReplyRequestData>({
      query: (data) => {
        const formData = new FormData();

        if (data.status_only_reply) {
          formData.append('request_id', String(data.request_id));
          formData.append('status_id', String(data.status_id));
          formData.append('status_only_reply', String(data.status_only_reply));
        } else {
          formData.append('request_id', String(data.request_id));
          formData.append('status_id', String(data.status_id));
          formData.append('text', String(data.text));
          formData.append('status_only_reply', String(data.status_only_reply));
          data?.files?.map((file: File) => {
            formData.append('files[]', file);
          });
        }

        return {
          url: `requests/reply`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: 'requests', id: 'inWork' }],
    }),
    getOperatorsList: builder.query<Array<IOperatorChangeListItem>, void>({
      query: () => ({
        url: `user/getOperatorsList`,
        method: 'GET',
      }),
    }),
    changeOperator: builder.mutation<
      IChangeOperatorResponse,
      IChangeOperatorRequest
    >({
      query: (data) => ({
        url: `requests/changeOperator`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'requests', id: 'inWork' }],
    }),
    getArchiveRequests: builder.query<
      IRequestArchiveResponse,
      { requestId: number; page: number; statusId: number }
    >({
      query: (data) => ({
        url: `requests/listArchive?per_page=10&page=${data.page}&filter[category_id]=${data.requestId}&filter[status]=${data.statusId}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'requests', id: 'archive' }],
    }),
    getRequestsCount: builder.query<IOperatorRequestCount, void>({
      query: () => ({
        url: `requests/requestsCount`,
        method: 'GET',
      }),
      providesTags: [
        { type: 'requests', id: 'free' },
        { type: 'requests', id: 'inWork' },
      ],
    }),
    getPaRequests: builder.query<IPARequestsData, IPARequestPayload>({
      query: (data) => ({
        url: `requests/getPARequests/${data.personalAccountId}?per_page=4&page=${data.page}&type=${data.requestTypeId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetFreeRequestsQuery,
  useTakeToWorkMutation,
  useGetRequestCounterQuery,
  useLazyGetRequestCounterQuery,
  useGetInWorkRequestsQuery,
  useGetOperatorsListQuery,
  useReplyRequestMutation,
  useChangeOperatorMutation,
  useGetArchiveRequestsQuery,
  useGetPaRequestsQuery,
  useGetRequestsCountQuery,
} = operatorRequestsApi;
