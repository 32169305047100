import React from 'react';

import { IconClose } from '@/components/Icons/IconClose';

import { IPaSearch } from '../usePaSearch';
import styles from './SelectedPA.module.css';

interface ISelectedPAProps {
  selectedUsers: IPaSearch[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<IPaSearch[]>>;
}

export const SelectedPA: React.FC<ISelectedPAProps> = (props) => {
  const { selectedUsers, setSelectedUsers } = props;

  if (selectedUsers.length === 0) {
    return null;
  }

  return (
    <div className={styles.selectedUserWrapper}>
      <div className={styles.selectedUserList}>
        {selectedUsers.map((user) => {
          return (
            <div key={user.id} className={styles.selectedUser}>
              <div className={styles.selectedUserName}>{user.label}</div>

              <div
                onClick={() => {
                  setSelectedUsers((prev) => [
                    ...prev.filter((item) => item.id !== user.id),
                  ]);
                }}
              >
                <IconClose className={styles.selectedUserIcon} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
