import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IEquipmentList,
  IGetMeterReadingHistoryRequest,
  IGetMeterReadingHistoryResponse,
  ISendMeterReadingsRequest,
  ISendMeterReadingsResponse,
} from '@/interfaces/meterReadings';

import { baseQueryWithReauth } from './baseQuery';

export const meterReadingsApi = createApi({
  reducerPath: 'meterReadingsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getEquipment: builder.query<IEquipmentList, void>({
      query: () => ({
        url: 'history/get-equipment',
        method: 'GET',
      }),
    }),
    getMeterReadingHistory: builder.query<
      IGetMeterReadingHistoryResponse,
      IGetMeterReadingHistoryRequest
    >({
      query: (data) => {
        let defaultUrl = `history/get-history?per_page=${data.perPage}&page=${data.page}`;

        if (data.filter.meterId) {
          defaultUrl += `&filter[meter_id]=${data.filter.meterId}`;
        }

        return {
          url: defaultUrl,
          method: 'GET',
        };
      },
    }),
    sendMeterReadings: builder.mutation<
      ISendMeterReadingsResponse,
      ISendMeterReadingsRequest
    >({
      query: (data) => ({
        url: `history/store`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetEquipmentQuery,
  useLazyGetEquipmentQuery,
  useGetMeterReadingHistoryQuery,
  useLazyGetMeterReadingHistoryQuery,
  useSendMeterReadingsMutation,
} = meterReadingsApi;
