import React, { lazy, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { UnavailablePersonalAccount } from '@/components/UnavailablePersonalAccount';
import { RootState } from '@/redux';

const AccrualsPage = lazy(() => import('@/routes/customer/Accruals'));
const ReceiptDetailesPage = lazy(
  () => import('@/routes/customer/Accruals/Receipt/Detailes'),
);
const Payment = lazy(() => import('@/routes/customer/Accruals/Payment'));

const AccrualsRoute: React.FC = () => {
  const {
    user: { data },
  } = useSelector((state: RootState) => state);

  const availablePersonalAccount = useMemo(() => {
    return data?.current_account.id;
  }, [data?.current_account]);

  return (
    <Routes>
      <Route path="/" element={<AccrualsPage />} />
      <Route path="receipt" element={<ReceiptDetailesPage />} />
      <Route
        path="payment/:personalAccountId"
        element={
          availablePersonalAccount ? (
            <Payment />
          ) : (
            <UnavailablePersonalAccount />
          )
        }
      />
    </Routes>
  );
};

export default AccrualsRoute;
