import React, { lazy, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import Loading from '@/components/Loading';
import { NotFoundPage } from '@/components/NotFoundPage';
import { UnavailablePersonalAccount } from '@/components/UnavailablePersonalAccount';
import { useSwitchPersonalAccontInProcess } from '@/providers/SwithingPersonalAccount';
import { RootState } from '@/redux';

import AccrualsRoute from './customer/Accruals/AccrualsRoute';
import PersonalAccountsRoute from './customer/PersonalAccounts/PersonalAccountsRoute';
import RequestsRote from './customer/Requests/RequestsRoute';

const ProfilePage = lazy(() => import('@/routes//customer/Profile'));
const MeterReadingPage = lazy(() => import('@/routes/customer/MeterReading'));
const SupplierPage = lazy(() => import('@/routes/customer/Supplier'));
const PaymentInfoPage = lazy(() => import('@/routes/customer/PaymentInfoPage'));
const NotificationsPage = lazy(() => import('@/routes/customer/Notifications'));
const PaymentHistoryPage = lazy(
  () => import('@/routes/customer/PaymentHistory'),
);
const ReceiptLinkPage = lazy(() => import('@/routes/common/ReceiptLink'));

const CustomerRouter: React.FC = () => {
  const {
    user: { data },
  } = useSelector((state: RootState) => state);
  const { switchInProcess } = useSwitchPersonalAccontInProcess();

  const availablePersonalAccount = useMemo(() => {
    return data?.current_account.id;
  }, [data?.current_account]);

  if (switchInProcess) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          availablePersonalAccount ? (
            <MeterReadingPage />
          ) : (
            <UnavailablePersonalAccount />
          )
        }
      />
      <Route
        path="accruals/*"
        element={
          availablePersonalAccount ? (
            <AccrualsRoute />
          ) : (
            <UnavailablePersonalAccount />
          )
        }
      />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="supplier" element={<SupplierPage />} />
      <Route
        path="requests/*"
        element={
          availablePersonalAccount ? (
            <RequestsRote />
          ) : (
            <UnavailablePersonalAccount />
          )
        }
      />
      <Route path="payment-info" element={<PaymentInfoPage />} />
      <Route path="personal-accounts/*" element={<PersonalAccountsRoute />} />
      <Route path="notifications" element={<NotificationsPage />} />
      <Route
        path="payment-history"
        element={
          availablePersonalAccount ? (
            <PaymentHistoryPage />
          ) : (
            <UnavailablePersonalAccount />
          )
        }
      />
      <Route path="receipt-link" element={<ReceiptLinkPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default CustomerRouter;
