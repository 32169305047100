import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSnackbarError } from '@/hooks/useSnackbackError';
import { RootState } from '@/redux';
import { updateError, updateServerError } from '@/redux/slices/error';

import { MainButton } from '../MainButton';
import styles from './GlobalError.module.css';

export const GlobalError: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onError = useSnackbarError();

  const { globalError } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (globalError.isServerError) {
      onError('Произошла внутренняя ошибка сервера');
      dispatch(updateServerError({ isServerError: false }));
    }
  }, [dispatch, globalError.isServerError, onError]);

  const onNavigate = () => {
    navigate('/auth');
    dispatch(updateError({ isError: false }));
  };

  if (!globalError.isError) {
    return null;
  }

  return (
    <div className={styles.root}>
      <p className={styles.errorText}>Что-то пошло не так</p>
      <MainButton onClick={onNavigate}>На страницу авторизации</MainButton>
    </div>
  );
};
