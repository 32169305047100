import * as Yup from 'yup';

export interface ICreateNotoficationForm {
  for_all: number;
  personalAccounts?: Array<number>;
  color: number;
  title: string;
  text: string;
  due_date: Date;
}

export const createNotificationFormShema: Yup.ObjectSchema<ICreateNotoficationForm> =
  Yup.object({
    for_all: Yup.number().required(),
    personalAccounts: Yup.array().when('for_all', {
      is: (for_all: number) => for_all === 0,
      then: (schema) => schema.min(1, 'Выберите хотя бы один лицевой счет'),
    }),
    color: Yup.number().required('Выберите цвет уведомления'),
    title: Yup.string().required('Введите заголовок').trim().max(255),
    text: Yup.string().required('Введите текст').trim().max(255),
    due_date: Yup.date().required('Выберите дату окончания показа уведомления'),
  });
