import { clsx } from 'clsx';
import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import Loading from '../Loading';
import styles from './MainButton.module.css';

interface IMainButtonProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
  appearance?: 'primary' | 'ghost' | 'cancel';
  size?: 'small' | 'large';
  fullWidth?: boolean;
  icon?: ReactNode;
  rounded?: boolean;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

export const MainButton: React.FC<IMainButtonProps> = (props) => {
  const {
    size = 'small',
    appearance = 'primary',
    children,
    icon,
    fullWidth = false,
    className,
    rounded,
    isLoading,
    ...restProps
  } = props;
  return (
    <button
      className={clsx(className, styles.root, {
        [styles.primary]: appearance === 'primary',
        [styles.ghost]: appearance === 'ghost',
        [styles.cancel]: appearance === 'cancel',
        [styles.small]: size === 'small',
        [styles.large]: size === 'large',
        [styles.fullWidth]: fullWidth,
        [styles.rounded]: rounded,
      })}
      {...restProps}
    >
      <div className={styles.content}>
        {icon && !isLoading && <span className={styles.icon}>{icon}</span>}

        {isLoading ? (
          <Loading
            className={styles.loadingOverride}
            circularColor={styles.circularColor}
            size={25}
            variant="internal"
          />
        ) : (
          children
        )}
      </div>
    </button>
  );
};
