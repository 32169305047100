import React from 'react';

interface Props {
  className?: string;
}

export const IconWarning: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 5.8125C10.8555 5.8125 5.46875 11.0453 5.46875 17.5C5.46875 23.9547 10.8555 29.1875 17.5 29.1875C24.1445 29.1875 29.5312 23.9547 29.5312 17.5C29.5312 11.0453 24.1445 5.8125 17.5 5.8125ZM17.5 7.9375C22.9365 7.9375 27.3438 12.2188 27.3438 17.5C27.3438 22.7812 22.9365 27.0625 17.5 27.0625C12.0635 27.0625 7.65625 22.7812 7.65625 17.5C7.65625 12.2188 12.0635 7.9375 17.5 7.9375ZM17.4915 11.1219C17.113 11.1219 16.8059 11.2344 16.5697 11.4591C16.3329 11.6833 16.2207 11.9638 16.2311 12.3006L16.4041 18.5947C16.4254 19.2991 16.7927 19.652 17.5075 19.652C18.2015 19.652 18.554 19.2996 18.5638 18.5957L18.7689 12.3162C18.7793 11.9793 18.6615 11.6959 18.4143 11.4664C18.1677 11.2363 17.8595 11.1219 17.4915 11.1219ZM17.5 21.0548C16.6605 21.0548 16.1285 21.7441 16.1285 22.329C16.1285 22.9139 16.6425 23.6031 17.5 23.6031C18.3575 23.6031 18.8715 22.9511 18.8715 22.329C18.8715 21.7069 18.3395 21.0548 17.5 21.0548Z"
        fill="#E73A5F"
      />
    </svg>
  );
};
