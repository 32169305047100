import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logout } from '@/redux/slices/user';

export function useLogout(): () => void {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogoutProfile = useCallback(() => {
    dispatch(logout());
    localStorage.removeItem('accessToken');

    navigate('/auth');
  }, [dispatch, navigate]);

  return onLogoutProfile;
}
