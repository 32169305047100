import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider, ruRU } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ErrorFallback } from './components/ErrorFallback';
import { GlobalError } from './components/GlobalError';
import Loading from './components/Loading';
import { Snackbar } from './components/Snackbar';
import { MediaQueryProvider } from './providers/MediaQuery';
import { SwitchAccountProvider } from './providers/SwitchAccount';
import { store } from './redux';
import RootRouter from './routes/RootRouter';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <MediaQueryProvider>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              Components={{
                error: Snackbar,
                success: Snackbar,
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ruLocale}
                localeText={
                  ruRU.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
              >
                <GlobalError />
                <React.Suspense fallback={<Loading title="Идет загрузка" />}>
                  <SwitchAccountProvider>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <RootRouter />
                    </ErrorBoundary>
                  </SwitchAccountProvider>
                </React.Suspense>
              </LocalizationProvider>
            </SnackbarProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </MediaQueryProvider>
    </Provider>
  );
};

export default App;
