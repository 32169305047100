import Modal from '@mui/material/Modal';
import { clsx } from 'clsx';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { IconClose } from '@/components/Icons/IconClose';
import { IconProfile } from '@/components/Icons/IconProfile';
import { IconExit } from '@/components/Icons/sidebar/IconExit';
import { useLogout } from '@/hooks/useLogout';
import { useCssQuery } from '@/providers/MediaQuery';
import { RootState } from '@/redux';

import { Sidebar } from '../../Sidebar';
import styles from './MobileMenu.module.css';

interface IMobileMenuProps {
  isShowMobileMenu: boolean;
  toggleShowMobileMenu: () => void;
}

export const MobileMenu: React.FC<IMobileMenuProps> = (props) => {
  const { isShowMobileMenu, toggleShowMobileMenu } = props;
  const { user } = useSelector((state: RootState) => state);
  const onLogoutProfile = useLogout();

  const { isMobile, isTabled } = useCssQuery();

  const handleClose = () => {
    toggleShowMobileMenu();
  };

  return (
    <Modal open={isShowMobileMenu} onClose={handleClose}>
      <div
        className={clsx(styles.root, {
          [styles.isMobile]: isMobile,
          [styles.isTabled]: isTabled,
        })}
      >
        <div className={styles.closeIconWrapper} onClick={toggleShowMobileMenu}>
          <IconClose className={styles.closeIcon} />
        </div>

        <div className={styles.profileInfo}>
          <div className={styles.profileIcon}>
            <IconProfile />
          </div>

          <span className={styles.profileName}>
            {user.data?.current_account.account_number}
          </span>
        </div>

        <Sidebar toggleShowMobileMenu={toggleShowMobileMenu} />

        <button className={styles.logoutButton} onClick={onLogoutProfile}>
          Выход
          <IconExit className={styles.logoutIcon} />
        </button>
      </div>
    </Modal>
  );
};
