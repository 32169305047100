import { clsx } from 'clsx';
import React from 'react';
import { GroupBase, Props } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';

import styles from './FormSelectAsync.module.css';

interface IFormSelectAsyncProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Props {
  fullWidth?: boolean;
  className?: string;
}

export function FormSelectAsync<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: IFormSelectAsyncProps<Option, IsMulti, Group> &
    AsyncProps<Option, IsMulti, Group>,
) {
  const { fullWidth, className, ...restProps } = props;

  return (
    <AsyncSelect
      {...restProps}
      className={clsx(styles.root, className, {
        [styles.fullWidth]: fullWidth,
      })}
      loadingMessage={({ inputValue }) => 'Поиск...'}
      noOptionsMessage={({ inputValue }) => 'Пользователи не найдены'}
      classNamePrefix={'custom-select'}
    />
  );
}
