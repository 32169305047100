import React from 'react';
import { useNavigate } from 'react-router-dom';

import { IconBack } from '../Icons/IconBack';
import styles from './BackButton.module.css';

export const BackButton: React.FC = () => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <button className={styles.root} onClick={onBack}>
      <IconBack />
    </button>
  );
};
