import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  useGetRequestCounterQuery,
  useGetRequestsCountQuery,
} from '@/redux/api/operator/operatorRequestsApi';
import { useGetRequestCategoryQuery } from '@/redux/api/requestsApi';
import {
  setRequestCategory,
  setRequestCount,
  updateRequestsCounters,
} from '@/redux/slices/requests';

export const useInitizlizedRequests = () => {
  const dispatch = useDispatch();

  const { data } = useGetRequestCounterQuery();
  const { data: categoryFetchData } = useGetRequestCategoryQuery();
  const { data: countRequest } = useGetRequestsCountQuery();

  useEffect(() => {
    if (data) {
      dispatch(
        updateRequestsCounters({
          freeRequestsCounter: data?.free,
          inWorkRequestsCounter: data?.in_work,
        }),
      );
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (categoryFetchData) {
      dispatch(
        setRequestCategory({
          categoryList: [...categoryFetchData],
        }),
      );
    }
  }, [categoryFetchData, dispatch]);

  useEffect(() => {
    if (countRequest) {
      dispatch(setRequestCount({ countRequests: { ...countRequest } }));
    }
  }, [countRequest, dispatch]);
};
