import { useSelector } from 'react-redux';

import { IUserPersonalAccount } from '@/interfaces/common';
import { RootState } from '@/redux';

export function useAvailablePersonalAccount():
  | IUserPersonalAccount[]
  | undefined {
  const { user } = useSelector((state: RootState) => state);

  const personalAccountForSwitch = user.data?.personal_accounts.filter(
    (personalAccount) => personalAccount.id !== user.data?.current_account.id,
  );

  return personalAccountForSwitch;
}
