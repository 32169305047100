import { clsx } from 'clsx';
import React from 'react';

import { EServerMessageType, IServerMessage } from '@/interfaces/common';

import styles from './ServerError.module.css';

interface IServerMessageProps {
  message?: IServerMessage | null;
  className?: string;
}

export const ServerMessage: React.FC<IServerMessageProps> = (props) => {
  const { message, className } = props;

  if (!message) {
    return null;
  }

  return (
    <div
      className={clsx(styles.root, className, {
        [styles.success]: message.type === EServerMessageType.Success,
        [styles.error]: message.type === EServerMessageType.Error,
      })}
    >
      {message.text}
    </div>
  );
};
