import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IEquipmentList,
  ISendMeterReadingsRequest,
  ISendMeterReadingsResponse,
} from '@/interfaces/meterReadings';

import { baseQuery } from './baseQuery';

export const meterWithoutRegisterApi = createApi({
  reducerPath: 'meterWithoutRegisterApi',
  baseQuery: baseQuery,
  tagTypes: ['meterReadingWithoutRegister'],
  endpoints: (builder) => ({
    getEquipmentWithoutRegister: builder.query<
      IEquipmentList,
      { personalAccountId: string }
    >({
      query: (data) => ({
        url: `history/get-equipment/${data.personalAccountId}`,
        method: 'GET',
      }),
      providesTags: ['meterReadingWithoutRegister'],
    }),
    sendMeterReadingsWithouRegister: builder.mutation<
      ISendMeterReadingsResponse,
      ISendMeterReadingsRequest
    >({
      query: (data) => ({
        url: `history/storeCounters`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['meterReadingWithoutRegister'],
    }),
  }),
});

export const {
  useGetEquipmentWithoutRegisterQuery,
  useSendMeterReadingsWithouRegisterMutation,
} = meterWithoutRegisterApi;
